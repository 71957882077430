/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


function Seo({ location, description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            shareImage
            shareImageTwitter
          }
        }
      }
    `
  )
  const language = lang || 'en';
  const metaDescription = description || site.siteMetadata.description
  

  let alt = 'de'; //default is english
  let altpath = '';
  let altloc = undefined;
  let canonical = undefined;
  if (location) {
    // Transform the current path from the current language to the alternative
    let splitpath = location.pathname.split('/');
    //the second element is the language
    if (splitpath[1] === 'de') {
      alt = 'en';
    }
    altpath ='/' + alt;
    for (let i=2; i < splitpath.length;i++) {    
      if (splitpath[i] !== '') {
        altpath = altpath + '/' + splitpath[i];
      }

    }
    //append the trailing slash
    altpath += '/';
    altloc = site.siteMetadata.siteUrl + altpath;

    //get the canonical URL
    canonical = site.siteMetadata.siteUrl + location.pathname;
  }


  let featureImage = site.siteMetadata.siteUrl + site.siteMetadata.shareImage;
  let featureImageTwitter = site.siteMetadata.siteUrl + site.siteMetadata.shareImageTwitter;

  if (image) {
    featureImage = site.siteMetadata.siteUrl + image;
    featureImageTwitter = featureImage;
  }


  return (
    
    <Helmet
      htmlAttributes={{
        lang, class: "h-100"
      }}
      bodyAttributes={{
        class: "d-flex flex-column h-100"
      }}
      title={title}

      titleTemplate={`%s | ${site.siteMetadata.title}`}

      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: featureImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: featureImageTwitter
        },
        {
          name: `Language`,
          content: language
        }
      ].concat(meta)}
    >
      
        <link rel="alternate" href={altloc} hrefLang={alt} key={alt}/>
        <link rel="canonical" href={canonical}/>
      
      </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  location: ``
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo;
